import {Api} from "../../api";

export const fetchMarks = (Redis = false, reset = false) => {
    return async (dispatch) => {
        const response = await Api.models.getTopMarks(Redis, reset)
        dispatch({type: 'FETCH_MARKS', payload: response})
    }
}

export const fetchMarksBaltex = (Redis = false, reset = false) => {
    return async (dispatch) => {
        const response = await Api.models.getTopMarksBaltex(Redis, reset)
        dispatch({type: 'FETCH_MARKS', payload: response})
    }
}


export const fetchTopMarks = () => {
    return async (dispatch) => {
        const response = await Api.models.getTopMarks()
        dispatch({type: 'FETCH_TOP_MARKS', payload: response})
    }
}

export const fetchModels = (id, select) => {
    return async (dispatch) => {
        const response = await Api.models.getModelsByMarkId(id, select)
        dispatch({type: 'FETCH_MODELS', payload: response})
    }
}

export const fetchTypes = (id, select) => {
    return async (dispatch) => {
        const response = await Api.models.getTypesByModelId(id, select)
        dispatch({type: 'FETCH_TYPES', payload: response})
    }
}