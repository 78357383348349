import styles from './index.module.scss'
import Link from 'next/link'

export default function Production({items}) {

    return (
        <div className={styles.News}>
            <h2>
                Новости производства
                <Link href={'/production/'}>Все новости производства</Link>
            </h2>
            <div className={styles.NewsList}>
                {items?.map(item => (
                    <Link key={item.ID} href={item.DETAIL_PAGE_URL}>
                        <a style={{backgroundImage: `url(${item.PICTURE_SRC})`}}>
                            <div className={styles.Info}>
                                <div className={styles.Name}>
                                    {item.NAME}
                                </div>
                            </div>
                        </a>
                    </Link>
                ))}
            </div>
        </div>
    )
}