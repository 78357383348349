import styles from "./index.module.scss";
import BaltexSlider from "~/components/baltex/slider";
import BaltexAdv from "~/components/baltex/adv";
import BaltexAbout from "~/components/baltex/about";
import BaltexNumbers from "~/components/baltex/numbers";
import Selector from "~/components/baltex/selector";
import {showPopup} from "~/redux/action-creaters/popup";
import {popupTypes} from "~/redux/reducers/popupReducer";
import {useDispatch} from "react-redux";
import News from "~/components/baltex/news";
import Production from "~/components/baltex/production";
import BaltexSections from "~/components/baltex/sections";

export default function BaltexHome({slider, adv, productions, news}: any) {
    const dispatch = useDispatch();
    return (
        <div className={styles.Baltex}>
            <BaltexSlider items={slider}/>

            <div className={styles.BaltexModels}>
                <div className='container'>
                    <Selector/>
                </div>
            </div>
            <div className='container'>
                <div className={styles.BaltexCatalog}>
                    <BaltexSections/>
                </div>
            </div>
            <div className={styles.BaltexNumbers}>

                <div className={styles.Video} onClick={() => {
                    dispatch(showPopup(popupTypes.video, 'Bda-0c1Kmok'))
                }}>
                    <span>
                        <svg width="18" height="22" viewBox="0 0 18 22" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 11L-9.78799e-07 21.3923L-7.02746e-08 0.607695L18 11Z" fill="white"/>
                        </svg>
                    </span>
                    <div>Смотреть видео</div>
                </div>
                <div className='container'>
                    <BaltexNumbers/>
                </div>
            </div>

            <div className='container'>
                <BaltexAbout/>
                <BaltexAdv items={adv}/>
                <div className={styles.Sert}>
                    <span>Получить сертификат на фаркоп BALTEX</span>
                    <div>
                        Скачать сертификат
                        <span>
                            <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3L5 7L9 3" stroke="white" strokeWidth="2"/>
                                <path d="M5 0V6.5" stroke="white" strokeWidth="2"/>
                                <rect y="11" width="10" height="2" fill="white"/>
                            </svg>
                        </span>

                    </div>
                </div>
            </div>


            <div className='container'>
                <div className={styles.BaltexBloks}>
                    <Production items={productions}/>
                    <News items={news}/>
                </div>
            </div>
        </div>
    )
}